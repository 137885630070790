import axios from "axios";

const apiUrl =
  "https://raw.githubusercontent.com/TejasG-007/ProjectData/main/projectdata.json";

function getDataFromApi() {
  return axios.get(apiUrl).then((res) => res.data);
}

export default getDataFromApi;
