import { Button } from "@chakra-ui/button";
import { useColorMode } from "@chakra-ui/color-mode";
import { Image } from "@chakra-ui/image";
import { Stack, Circle, Flex, Box, Text } from "@chakra-ui/layout";
import { useMediaQuery } from "@chakra-ui/media-query";
import React from "react";
import pic from "../assets/profilepic.png";

function Header({ personalData }) {
  const { colorMode } = useColorMode();
  const isDark = colorMode === "dark";
  const [isNotSmallerScreen] = useMediaQuery("(min-width:600px)");
  const { name = "Facing DNS Issue" } = personalData ?? {};
  const {
    desc = [
      "Please try to Change the Network.\n This is usually happened with JIO network",
    ],
  } = personalData ?? {};

  return (
    <Stack>
      <Circle
        position="absolute"
        bg="blue.100"
        opacity="0.1"
        w="300px"
        h="300px"
        alignSelf="flex-end"
      />
      <Flex
        direction={isNotSmallerScreen ? "row" : "column"}
        spacing="200px"
        p={isNotSmallerScreen ? "32" : "0"}
        alignSelf="flex-start"
      >
        <Box mt={isNotSmallerScreen ? "0" : 16} align="flex-start">
          <Text fontSize="5xl" fontWeight="semibold">
            {personalData && "Hi, I am"}
          </Text>
          <Text
            fontSize="7xl"
            fontWeight="bold"
            bgGradient="linear(to-r, cyan.400, blue.500, purple.600)"
            bgClip="text"
          >
            {name}
          </Text>
          <Text
            justifyContent="center"
            color={isDark ? "gray.200" : "gray.500"}
          >
            {desc[0]}
          </Text>
          <Text
            justifyContent="center"
            color={isDark ? "gray.200" : "gray.500"}
          >
            {desc[1]}
          </Text>
          <Button
            mt={8}
            colorScheme="blue"
            onClick={() =>
              window.open(
                "https://raw.githubusercontent.com/TejasG-007/ProjectData/main/resume/TejasGathekar.pdf"
              )
            }
          >
            Resume
          </Button>
        </Box>
        <Image
          alignSelf="center"
          mt={isNotSmallerScreen ? "0" : "12"}
          mb={isNotSmallerScreen ? "0" : "12"}
          borderRadius="full"
          backgroundColor="transparent"
          boxShadow="lg"
          boxSize="300px"
          src={
            "https://raw.githubusercontent.com/TejasG-007/ProjectData/main/profile.png"
          }
        />
      </Flex>
    </Stack>
  );
}

export default Header;
