import { Box, Text, SimpleGrid } from "@chakra-ui/layout";
import React from "react";
import { Image } from "@chakra-ui/image";
import { Divider } from "@chakra-ui/layout";
import {
  Accordion,
  AccordionItem,
  AccordionPanel,
  AccordionButton,
  AccordionIcon,
  Button,
} from "@chakra-ui/react";
import { Stack } from "@chakra-ui/react";
import { useMediaQuery } from "@chakra-ui/media-query";
import { BsGithub } from "react-icons/bs";
import { GiEarthAmerica } from "react-icons/gi";

function Social({ projectData }) {
  const [isNotSmallerScreen] = useMediaQuery("(min-width:600px)");
  return (
    <div>
      {projectData && (
        <>
          <Text
            fontSize="2xl"
            fontWeight="bold"
            bgGradient="linear(to-r, cyan.400, blue.500, purple.600)"
            bgClip="text"
            marginBottom={"3"}
          >
            Projects
          </Text>
          <Divider></Divider>

          <SimpleGrid columns={isNotSmallerScreen ? [3] : [1.5]} spacing="10">
            {projectData.map((item) => (
              <Box
                key={item.name}
                padding="2"
                overflow="hidden"
                rounded="lg"
                mazw="sm"
                boxShadow={"xl"}
              >
                <Image src={item.images[0]} rounded="lg" />
                <Box textAlign="center" margin="1">
                  <Text
                    as="b"
                    textAlign="center"
                    style={{ textAlign: "center" }}
                  >
                    {item.name}
                  </Text>
                </Box>
                <Box>
                  <Accordion allowMultiple>
                    <AccordionItem>
                      <h1>
                        <AccordionButton
                          _expanded={{
                            bg: "cyan.800",
                            color: "white",
                            fontSize: "xl",
                            fontWeight: "bold",
                            rounded: "lg",
                          }}
                        >
                          <Box as="span" flex="1" textAlign="center">
                            Description
                          </Box>
                          <AccordionIcon />
                        </AccordionButton>
                      </h1>
                      <AccordionPanel>
                        <Text>{item.desc}</Text>
                      </AccordionPanel>
                    </AccordionItem>
                  </Accordion>
                </Box>
                <Box margin={5}></Box>
                <Stack direction="row">
                  {item.web_link && (
                    <Button onClick={() => window.open(item.web_link)}>
                      <GiEarthAmerica></GiEarthAmerica>
                      <Box w={"1"}></Box>
                      WEB-LINK
                    </Button>
                  )}
                  <Box margin={5}></Box>
                  {item.git_link && (
                    <Button onClick={() => window.open(item.git_link)}>
                      <BsGithub></BsGithub>
                      <Box w={"1"}></Box>
                      GITHUB
                    </Button>
                  )}
                </Stack>
              </Box>
            ))}
          </SimpleGrid>
        </>
      )}
    </div>
  );
}

export default Social;
