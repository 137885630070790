import { IconButton } from "@chakra-ui/button";
import { useColorMode } from "@chakra-ui/color-mode";
import { Flex, VStack, Heading, Spacer, Text } from "@chakra-ui/layout";
import {
  FaSun,
  FaMoon,
  FaInstagram,
  FaGithub,
  FaLinkedin,
} from "react-icons/fa";
import Header from "./components/Header";
import Profile from "./components/Profile";
import Social from "./components/Projects";
import { React, useEffect, useState } from "react";
import Loader from "./assets/tejasg.gif";
import Experience from "./components/Experience";
import getDataFromApi from "./api_helper/api_helper";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";

function App() {
  const themeData = extendTheme({
    initialColorMode: "dark",
  });
  const [data, setData] = useState({});
  const { colorMode, toggleColorMode } = useColorMode();
  const isDark = colorMode === "dark";
  const [isloading, setloading] = useState(false);

  useEffect(() => {
    getDataFromApi().then((res) => setData(res));
    showing();
  }, []);

  const showing = () => {
    setloading(true);
    setTimeout(() => {
      setloading(false);
    }, 2000);
  };

  if (!data)
    return (
      <div
        style={{
          position: "fixed",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <img height="350" width="350" src={Loader} alt="loader"></img>
      </div>
    );

  return (
    <>
      <ChakraProvider theme={themeData}>
        {isloading && data != null ? (
          <div
            style={{
              position: "fixed",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <img height="350" width="350" src={Loader} alt="loader"></img>
          </div>
        ) : (
          <VStack p={5}>
            <Flex w="100%">
              <Heading ml="8" size="md" fontWeight="semibold" color="cyan.400">
                {"<TejasG/>"}
              </Heading>
              <Spacer></Spacer>

              <IconButton
                icon={<FaLinkedin />}
                isRound="true"
                onClick={() =>
                  window.open("https://www.linkedin.com/in/tejas-gathekar/")
                }
              ></IconButton>
              <IconButton
                ml={2}
                icon={<FaInstagram />}
                isRound="true"
                onClick={() =>
                  window.open("https://www.instagram.com/tejasg007/")
                }
              ></IconButton>
              <IconButton
                ml={2}
                icon={<FaGithub />}
                isRound="true"
                onClick={() => window.open("https://github.com/TejasG-007")}
              ></IconButton>
              <IconButton
                ml={8}
                icon={isDark ? <FaSun /> : <FaMoon />}
                isRound="true"
                onClick={toggleColorMode}
              ></IconButton>
            </Flex>
            <Header personalData={data.personal_data}></Header>
            <Experience exp={data.experience}></Experience>
            <Social projectData={data.project_data}></Social>
            <Profile></Profile>
            {data.site_data && (
              <Text
                fontSize="1xl"
                fontWeight="Normal"
                bgGradient="linear(to-r, cyan.400, blue.500, purple.600)"
                bgClip="text"
              >
                {data.site_data.copyright}
              </Text>
            )}
          </VStack>
        )}
      </ChakraProvider>
    </>
  );
}

export default App;
