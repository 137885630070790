import {
  Box,
  Heading,
  ListIcon,
  ListItem,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import React from "react";
import { FaMinus } from "react-icons/fa";
import { SimpleGrid } from "@chakra-ui/react";
import { useMediaQuery } from "@chakra-ui/media-query";

function Experience({ exp }) {
  const [isNotSmallerScreen] = useMediaQuery("(min-width:600px)");
  return (
    <div>
      {exp && (
        <div>
          <Text
            fontSize="2xl"
            fontWeight="bold"
            marginBottom={"2"}
            bgGradient="linear(to-r, cyan.400, blue.500, purple.600)"
            bgClip="text"
          >
            Work Experience
          </Text>
          <SimpleGrid columns={isNotSmallerScreen ? [2] : [1.5]} spacing="10">
            {exp.length > 0 &&
              exp.map((company) => {
                return (
                  <Box
                    p="6"
                    borderWidth="1px"
                    borderRadius="lg"
                    overflow="hidden"
                    marginBottom="1"
                    key={company.name}
                  >
                    <Box>
                      <Heading size="md" marginBottom={1}>
                        {company.name}
                      </Heading>
                      <Text>
                        {company.from} to {company.to}
                      </Text>
                      {company.desc && (
                        <UnorderedList styleType="none">
                          {company.desc.map((item) => {
                            return (
                              <ListItem>
                                <ListIcon as={FaMinus} color="green.500" />
                                {item}
                              </ListItem>
                            );
                          })}
                        </UnorderedList>
                      )}
                    </Box>
                  </Box>
                );
              })}
          </SimpleGrid>
        </div>
      )}
    </div>
  );
}
export default Experience;
